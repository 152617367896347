<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        <v-switch
                            dense
                            inset
                            true-value="ativo"
                            false-value="inativo"
                            hide-details
                            class="pb-2"
                            v-model="form.status"
                            :label="form.status == 'ativo' ? 'Ativo' : 'Inativo'"
                        />
                        <v-spacer />
                        {{ form.id ? 'Edição' : 'Cadastro' }} de Cupom
                        <v-spacer />
                    </v-card-title>
                    <v-card-text align="center" justify="center">
                        <v-text-field
                            label="Nome"
                            v-model="form.descricao"
                            ref="descricao"
                            outlined
                            dense
                            hide-details
                            class="mt-3"
                        />
                        <v-text-field
                            label="Código do Cupom"
                            v-model="form.codigo"
                            v-mask="['XXXXXXXXXXXXXXXXXXXX']"
                            outlined
                            dense
                            hide-details
                            class="mt-3"
                        />
                        <v-text-field
                            label="Desconto de Valor"
                            v-model="form.valor"
                            placeholder="0.00"
                            prefix="R$"
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                            outlined
                            dense
                            hide-details
                            class="mt-3"
                        />
                        <v-text-field
                            label="Desconto Percentual"
                            v-model="form.percentual"
                            placeholder="0.00"
                            prefix="%"
                            v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                            outlined
                            dense
                            hide-details
                            class="mt-3"
                        />
                        <v-switch
                            dense
                            inset
                            hide-details
                            class="pb-2"
                            v-model="form.frete_gratis"
                            label="Entrega Grátis"
                        />
                        <!-- <v-text-field
                            label="Quantidade Disponivel"
                            v-model="form.quantidade"
                            type="number"
                            v-mask="['#','##','###','####']">
                        </v-text-field>
                        <v-text-field
                            label="Data de Validade"
                            type="date"
                            v-model="form.validade"
                            placeholder="DD/MM/YYYY"
                            hide-details
                        /> -->
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/cupons">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Cupom',

    components: { BaseContainer },

    data: () => ({
        loading: false,
        form: {
            descricao: '',
            codigo: '',
            valor: '',
            percentual: '',
            // validade: '',
            // quantidade: null,
            frete_gratis: false,
            ativo: true,
            status: 'ativo',
        },
    }),

    mounted() {
        let dados = this.$store.getters.getData;

        if (dados?.id) {
            this.form = dados;
            this.$store.commit('setData', {});
        }
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`cupons/${id}`, this.form).then(() => {
                    this.$router.push('/cupons');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('cupons', this.form).then(() => {
                this.$router.push('/cupons');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
